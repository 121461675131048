import { Flex, Spacer } from 'vcc-ui';

import { Link, Paragraph } from '@collab/atoms';
import { Callout } from '@collab/molecules';

import useAkamaiAuth from 'features/akamai-auth/useAkamaiAuth';

const brandUrl = '/i/design-strategy/introduction/';

const BrandIntro: React.FC = () => {
  const { isVerified } = useAkamaiAuth();
  const content = isVerified
    ? 'Read about our design strategy.'
    : 'Log in with your CDSID to read about our design strategy.';

  return (
    <Flex>
      <Spacer size={[8, 12]} />
      <Callout
        title="Design for life"
        content={<Paragraph>{content}</Paragraph>}
        button={
          <Link
            href={brandUrl}
            className="button-outlined"
            aria-label="Explore our design strategy"
          >
            Explore
          </Link>
        }
      />
    </Flex>
  );
};

export default BrandIntro;
